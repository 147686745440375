/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { IconButton, Typography, withStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  compose,
  find,
  isEmpty,
  isNil,
  propEq,
  symmetricDifference,
} from "ramda";
import logInUser from "../LogInUser/LogInUser";
import { closeReadingLog, fetchReadingLog, saveReadingLog } from "./actions";
import { fetchStarsPercentages } from "../../../actions";
import { changeReminderTimeStyles } from "./styles";
import i18n from "../../../i18n/i18next";
import ReadingLogButtonV5 from "./ReadingLogButtonV5";
import {
  openStarsIncrease,
  setStarsIncreaseData,
} from "../StarsIncrease/actions";
import InnerHeight from "../../helpers/InnerHeight";
import NextButton from "../../Icons/NextButton";
import Loading from "../../Loading";
import ActionPlanTopBar from "../ActionPlan/ActionPlanTopBar";
import ReadingLogSelectorRPTBooks from "./ReadingLogSelectorRPTBooks";
import ReadingLogSelectorMinutes from "./ReadingLogSelectorMinutes";
import RptProgressBar from "./RptProgressBar";

const styles = (theme) => changeReminderTimeStyles(theme);

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const ariaDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class ReadingLogV5 extends Component {
  eqValues = compose(isEmpty, symmetricDifference);

  constructor(props) {
    super(props);
    this.state = {
      timeTogether: null,
      timeIndependently: null,
      minutesHome: null,
      booksHome: null,
      total: 0,
      feedbackData: [],
      selectedDay: null,
      showProgress: false,
      increase: 0,
      isAtMax: false,
      saving: false,
      showRibbon: false,
      updatedTotal: false,
    };
  }

  componentDidMount() {
    const {
      softLogin,
      fetchReadingLog,
      readingLog: { readingLogData },
    } = this.props;

    if (!isEmpty(softLogin)) {
      if (isEmpty(readingLogData)) {
        fetchReadingLog(
          softLogin.activeChild.id,
          softLogin.activeChild.authToken,
          softLogin.activeChild.currentApa.apaId,
          softLogin.activeChild.currentApa.id
        );
      }
    } else {
      logInUser(this.props);
    }
  }

  componentDidUpdate() {
    const {
      softLogin,
      fetchReadingLog,
      readingLog: { readingLogData },
    } = this.props;

    const { feedbackData } = this.state;

    const dataChanged = !this.eqValues(readingLogData, feedbackData);

    if (!isEmpty(softLogin)) {
      if (isEmpty(readingLogData)) {
        fetchReadingLog(
          softLogin.activeChild.id,
          softLogin.activeChild.authToken,
          softLogin.activeChild.currentApa.apaId,
          softLogin.activeChild.currentApa.id
        );
      } else if (dataChanged) {
        this.getDefaultValues();
      }
    }
  }

  getDefaultValues = () => {
    const {
      readingLog: { readingLogData },
    } = this.props;
    let { selectedDay } = this.state;

    if (isEmpty(readingLogData)) {
      return;
    }

    selectedDay = this.isCurrentWeek()
      ? new Date().toDateString().split(" ")[0]
      : selectedDay;

    const idx = days.indexOf(selectedDay);
    const readingLog = readingLogData[idx] || {};
    const booksHome = readingLog.books_read_at_home || null;
    const minutesHome = readingLog.minutes_home || null;
    const timeTogether = readingLog.minutes_together || null;
    const timeIndependently = readingLog.minutes_alone || null;

    this.setState({
      feedbackData: readingLogData,
      booksHome,
      minutesHome,
      timeTogether,
      timeIndependently,
      selectedDay,
    });
  };

  selectActiveDay = (day) => {
    const idx = days.indexOf(day);
    this.setState((prevState) => ({
      selectedDay: day,
      booksHome:
        prevState.feedbackData[idx].books_read_at_home !== null
          ? prevState.feedbackData[idx].books_read_at_home
          : null,
      minutesHome:
        prevState.feedbackData[idx].minutes_home !== null
          ? prevState.feedbackData[idx].minutes_home
          : null,
      timeTogether:
        prevState.feedbackData[idx].minutes_together !== null
          ? prevState.feedbackData[idx].minutes_together
          : null,
      timeIndependently:
        prevState.feedbackData[idx].minutes_alone !== null
          ? prevState.feedbackData[idx].minutes_alone
          : null,
    }));
  };

  changeTogetherValue = (value) => {
    const { feedbackData, selectedDay } = this.state;
    const idx = days.indexOf(selectedDay);
    const fbD = feedbackData;

    fbD[idx].minutes_together = parseInt(value, 10);
    fbD[idx].minutes_alone = fbD[idx].minutes_alone || null;

    this.setState(
      {
        timeTogether: parseInt(value, 10),
        feedbackData: fbD,
      },
      () => {
        this.saveLog();
      }
    );
  };

  changeBooksValue = (value) => {
    const { feedbackData, selectedDay } = this.state;
    const idx = days.indexOf(selectedDay);
    const fbD = feedbackData;

    fbD[idx].books_read_at_home = parseInt(value, 10);

    this.setState(
      {
        booksHome: parseInt(value, 10),
        feedbackData: fbD,
      },
      () => {
        this.saveLog();
      }
    );
  };

  changeIndependentlyValue = (value) => {
    const { feedbackData, selectedDay } = this.state;
    const idx = days.indexOf(selectedDay);
    const fbD = feedbackData;

    fbD[idx].minutes_together = fbD[idx].minutes_together || null;
    fbD[idx].minutes_alone = parseInt(value, 10);

    this.setState(
      {
        timeIndependently: parseInt(value, 10),
        feedbackData: fbD,
      },
      () => {
        this.saveLog();
      }
    );
  };

  changeMinutesHomeValue = (value) => {
    const { feedbackData, selectedDay } = this.state;
    const idx = days.indexOf(selectedDay);
    const fbD = feedbackData;

    fbD[idx].minutes_home = parseInt(value, 10);

    this.setState(
      {
        minutesHome: parseInt(value, 10),
        feedbackData: fbD,
      },
      () => {
        this.saveLog();
      }
    );
  };

  saveLog = () => {
    if (!this.isActiveDayFilled()) {
      return;
    }

    const {
      softLogin: {
        activeChild: { id, firstName, authToken, currentApa },
      },
      saveReadingLog,
      readingLog,
      setStarsIncreaseData,
      fetchStarsPercentages,
    } = this.props;
    const { feedbackData } = this.state;
    // sendEvent("ReadingLog: Save", `{userID: ${id}, apID: ${readingLog.apId}}`);
    // KmService.record("ReadingLog: Save", { "userID": id, "apID": readingLog.apId, "sessionName": isNil(softLogin) ? "unknown" : softLogin.activeChild.programs[0].name });

    this.setState({ saving: true });
    saveReadingLog(id, authToken, feedbackData, readingLog.apaId).then(
      (response) => {
        fetchStarsPercentages(id, authToken, readingLog.apaId);
        this.setState(
          (prevState) => ({
            increase: prevState.increase + response.difference,
            isAtMax: response.max,
            updatedTotal: response.total,
          }),
          () => {
            let message = "";
            if (response.max) {
              message = i18n.t("recordProgress.Maximum reading stars", {
                firstName,
              });
            } else if (this.state.increase > 0) {
              message = i18n.t("recordProgress.Reading stars earned", {
                firstName,
                stars: this.state.increase,
              });
            } else {
              message = i18n.t("recordProgress.Stars saved", { firstName });
            }
            setStarsIncreaseData(
              message,
              "/images/check-in.png",
              response.max,
              response.stars,
              response.totalStars,
              `/family-companion/goal/${currentApa.id}/1/${id}/${authToken}`
            );
            this.setState({ saving: false });
          }
        );
      }
    );
  };

  close = () => {
    const {
      closeReadingLog,
      history,
      softLogin: {
        activeChild: { id, authToken, currentApa },
      },
      openStarsIncrease,
    } = this.props;
    const { increase, isAtMax } = this.state;

    this.setState({ showRibbon: true }, () => {
      setTimeout(() => {
        this.setState({ showRibbon: false });
        closeReadingLog();
        if (increase !== 0 || isAtMax) {
          openStarsIncrease();
        } else {
          history.push(
            `/family-companion/goal/${currentApa.id}/1/${id}/${authToken}`
          );
        }
      }, 100);
    });
  };

  dismiss = () => {
    const { history } = this.props;
    history.goBack();
  };

  isCurrentWeek = () => {
    const { softLogin, readingLog } = this.props;
    return softLogin.activeChild.currentApa.apaId === readingLog.apaId;
  };

  clearState = () => {
    const day = this.isCurrentWeek()
      ? new Date().toDateString().split(" ")[0]
      : null;
    this.setState({
      selectedDay: day,
      booksHome: 0,
      minutesHome: 0,
      timeTogether: 0,
      timeIndependently: 0,
      feedbackData: [],
    });
  };

  isActiveDayFilled = () => {
    const { timeIndependently, timeTogether, minutesHome, booksHome } =
      this.state;

    return (
      (!isNil(timeTogether) &&
        !isEmpty(timeTogether) &&
        !isNil(timeIndependently) &&
        !isEmpty(timeIndependently) &&
        !Number.isNaN(Number(timeTogether + timeIndependently))) ||
      (!isNil(minutesHome) && !isEmpty(minutesHome)) ||
      (!isNil(booksHome) && !isEmpty(booksHome))
    );
  };

  closeShowProgress = () => {
    const {
      history,
      softLogin: {
        activeChild: { id, authToken, currentApa },
      },
    } = this.props;
    this.setState({ showProgress: false, increase: 0, isAtMax: false });
    history.push(
      `/family-companion/goal/${currentApa.id}/1/${id}/${authToken}`
    );
  };

  getAssignmentForWeek = (program, week) => {
    const { archivedApas, currentApa, inactiveApas } = program;
    const findApaForWeek = find(propEq("weekNumber", week));

    return (
      findApaForWeek(archivedApas) ||
      (!isNil(currentApa) && findApaForWeek([currentApa])) ||
      findApaForWeek(inactiveApas)
    );
  };

  findSelectedProgram = (programs) => {
    const {
      readingLog: { apaId },
    } = this.props;
    return programs.find(
      (p) =>
        (!isNil(p.currentApa) && p.currentApa.apaId === apaId) ||
        p.archivedApas.some((a) => a.apaId === apaId) ||
        p.inactiveApas.some((a) => a.apaId === apaId)
    );
  };

  changeWeek = (weekNumber) => {
    const {
      softLogin: {
        activeChild: { id, authToken, programs },
      },
      fetchReadingLog,
    } = this.props;

    const selectedProgram = this.findSelectedProgram(programs);
    if (weekNumber <= 0 || weekNumber >= selectedProgram.instructionWeeks + 1) {
      return;
    }

    const weekAssignment = this.getAssignmentForWeek(
      selectedProgram,
      weekNumber
    );
    fetchReadingLog(
      id,
      authToken,
      weekAssignment.apaId,
      weekAssignment.id
    ).then(() => this.getDefaultValues());
  };

  render() {
    const { classes, softLogin, readingLog, windowHeight, actionPlanDetail } =
      this.props;
    const {
      booksHome,
      minutesHome,
      selectedDay,
      feedbackData,
      saving,
      updatedTotal,
    } = this.state;

    let activeDay = selectedDay;
    const [today] = new Date().toDateString().split(" ");

    if (
      isEmpty(softLogin) ||
      isNil(softLogin) ||
      isNil(readingLog) ||
      isEmpty(readingLog.readingLogData)
    ) {
      return <Loading />;
    }

    if (this.isCurrentWeek() && isNil(activeDay)) {
      activeDay = today;
    }

    if (isEmpty(feedbackData)) {
      this.getDefaultValues();
    }

    const selectedProgram = this.findSelectedProgram(
      softLogin.activeChild.programs
    );

    const {
      currentApa,
      instructionWeeks,
      allowAddingPoints = true,
      goalSetting = "assessment-based",
    } = selectedProgram;

    const canSwitchToNextWeek = isNil(currentApa)
      ? readingLog.week !== instructionWeeks
      : currentApa.weekNumber > readingLog.week;

    const showWeDidIt = !softLogin.activeChild.logLink.includes("/0/0/");

    return (
      <>
        <ActionPlanTopBar
          showSteps={false}
          strategyName={actionPlanDetail?.actionPlan?.title || ""}
        />
        <div
          className={classes.container}
          style={{
            height: windowHeight - 160,
            marginTop: 20,
            marginBottom: 50,
          }}
        >
          {goalSetting !== "assessment-based" && (
            <div className={classes.rptTopBarContainer}>
              <RptProgressBar
                updatedTotal={updatedTotal}
                activeChild={softLogin.activeChild}
              />
            </div>
          )}

          <div className={classes.weekContainer}>
            <div className={classes.weekSwitch}>
              <div className={classes.weekItem}>
                {readingLog.week !== 1 && (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => this.changeWeek(readingLog.week - 1)}
                  >
                    <ChevronLeftIcon fontSize="large" />
                  </IconButton>
                )}
              </div>
              <div className={classes.weekItem} style={{ flex: 2 }}>
                <Typography variant="h3" align="center" style={{ padding: 0 }}>
                  {i18n.t("Goal.Week", { number: readingLog.week })}
                </Typography>
              </div>
              <div className={classes.weekItem}>
                {canSwitchToNextWeek && (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => this.changeWeek(readingLog.week + 1)}
                  >
                    <ChevronRightIcon fontSize="large" />
                  </IconButton>
                )}
              </div>
            </div>
            <Typography
              align="center"
              className={classes.whenBody}
              style={{ margin: 0, paddingBottom: 5 }}
            >
              {i18n.t("recordProgress.Tap on a circle to select the day")}
            </Typography>

            <div className={classes.days}>
              {!isEmpty(feedbackData) &&
                days.map((day, i) => {
                  let value;
                  if (goalSetting === "books-read") {
                    value = feedbackData[i].books_read_at_home;
                  } else {
                    value = feedbackData[i].minutes_home;
                  }
                  if (isNil(value)) {
                    value = "-";
                  }
                  return (
                    <ReadingLogButtonV5
                      day={day}
                      ariaDay={ariaDays[days.indexOf(day)]}
                      key={`button_${i}`}
                      value={value}
                      aria-label={ariaDays[today]}
                      isActive={activeDay === day}
                      isCurrent={this.isCurrentWeek() && today === day}
                      disabled={
                        (this.isCurrentWeek() && i > days.indexOf(today)) ||
                        !allowAddingPoints
                      }
                      click={this.selectActiveDay}
                      status={value !== "-" ? "positive" : null}
                    />
                  );
                })}
            </div>
          </div>
          {goalSetting !== "books-read" && (
            <ReadingLogSelectorMinutes
              activeDay={activeDay}
              allowAddingPoints={allowAddingPoints}
              value={minutesHome}
              changeValue={this.changeMinutesHomeValue}
            />
          )}
          {goalSetting === "books-read" && (
            <ReadingLogSelectorRPTBooks
              activeDay={activeDay}
              allowAddingPoints={allowAddingPoints}
              value={booksHome}
              changeValue={this.changeBooksValue}
            />
          )}

          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
              padding: 8,
            }}
          >
            {showWeDidIt ? (
              allowAddingPoints ? (
                <NextButton
                  buttonStyle={{ marginBottom: 0 }}
                  onClick={this.close}
                  isDisabled={saving || !this.isActiveDayFilled()}
                  text={
                    saving
                      ? i18n.t("Feedback.Saving")
                      : i18n.t("ActionPlan.We did it")
                  }
                />
              ) : (
                <Typography align="center" style={{ width: "100%" }}>
                  {i18n.t("recordProgress.Program is over")}
                </Typography>
              )
            ) : (
              <div />
            )}
          </div>

          <InnerHeight />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  windowHeight: state.windowHeight,
  softLogin: state.softLogin,
  readingLog: state.readingLog,
  actionPlanDetail: state.actionPlanDetail,
});

export default withRouter(
  connect(mapStateToProps, {
    saveReadingLog,
    closeReadingLog,
    setStarsIncreaseData,
    openStarsIncrease,
    fetchStarsPercentages,
    fetchReadingLog,
  })(withStyles(styles)(ReadingLogV5))
);
